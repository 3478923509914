import { useStaticQuery, graphql } from 'gatsby';

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "posts" } }) {
        nodes {
          childMdx {
            frontmatter {
              title
              slug
              author
              date
              lead
            }
            timeToRead
          }
        }
      }
    }
  `);

  return data.allFile.nodes.map((post) => ({
    title: post.childMdx.frontmatter.title,
    author: post.childMdx.frontmatter.author,
    slug: post.childMdx.frontmatter.slug,
    date: post.childMdx.frontmatter.date,
    lead: post.childMdx.frontmatter.lead,
    timeToRead: post.childMdx.timeToRead,
  }));
};

export default usePosts;
