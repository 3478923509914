import React from 'react';
import styled from '@emotion/styled';

import usePosts from '../hooks/usePosts';
import Layout from '../components/layout';
import { Hero, PostPreview, Title, Subtitle } from '../components/blog';

const BlogLayout = styled('section')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Blog = () => {
  const posts = usePosts();

  return (
    <Layout fullWidth={true}>
      <Hero>
        <Title>Blog</Title>
        <Subtitle>Memoirs and moments of my professional journey.</Subtitle>
      </Hero>

      <BlogLayout>
        {posts.map((post) => (
          <PostPreview key={`post-${post.slug}`} post={post} />
        ))}
      </BlogLayout>
    </Layout>
  );
};

export default Blog;
